import "../App.css";
import React, { Fragment } from "react";
import ComingSoon from "./ComingSoon";
const Header = () => {
  return (
    <Fragment>
      <div className="header"></div>
      <div className="main-heading-container">
          <h1 className="main-heading">Taimoor.ca</h1>
      </div>
      <ComingSoon/>
    </Fragment>
  );
};
export default Header;
