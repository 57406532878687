import './App.css';
import Home from './Components/Home'
function App() {
  return (
  <div>
    <Home />
  </div>
  );
}

export default App;
